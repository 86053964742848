import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colors from 'src/styles/colors';

const InnerCircleStyles = { color: `${colors.secondaryBackground}`, position: 'inherit', zIndex: 5 };
const OuterCircleStyles = { color: 'rgba(197, 197, 197,0.4)', position: 'absolute', left: 0 };
const StepsContainerStyles = {
  top: '-6px',
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const StepsTypographyStyles = { color: `${colors.secondaryBackground}`, fontSize: '14px', fontWeight: 'bold' };
interface Props {
  step: number;
}

const CircularProgressSteps: React.FC<Props> = ({ step }) => {
  return (
    <Box sx={{ position: 'relative', top: '3px' }}>
      <CircularProgress variant="determinate" sx={InnerCircleStyles} size={65} value={step * 16.6} />
      <CircularProgress variant="determinate" sx={OuterCircleStyles} size={65} value={100} />
      <Box sx={StepsContainerStyles}>
        <Typography sx={StepsTypographyStyles} variant="body1" component="div">
          6 / {step}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircularProgressSteps;
