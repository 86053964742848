import styled from '@emotion/styled';
import jonniLogoBlue from 'assets/jonniLogoBlue.svg';
import whatsappBlue from 'assets/whatsappBlue.svg';
import whatsappWhite from 'assets/whatsappWhite.svg';
import { breakpoints, getMQ } from 'src/helpers';
import colors from 'src/styles/colors';

export const LinkStyle = { textDecoration: 'none' };

export const ActiveLinkStyle = { fontWeight: 'bold' };

export const TopBarBackground = styled.div`
  // background: ${colors.primary};
  position: fixed;
  width: 100%;
  height: 80px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${getMQ(breakpoints.l)} {
    background: transparent;
    height: 100px;
  }
`;

export const TopBarContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  width: 100%;
  // background: ${colors.darkBlue};

  ${getMQ(breakpoints.l)} {
    //   background: transparent;
    height: 100px;
    width: 100%;
    padding: 0 31px;
    justify-content: space-between;
  }

  ${getMQ(breakpoints.xl)} {
    padding: 0 40px;
  }
`;

export const StartBtnContainer = styled.div`
  background: ${colors.secondaryBackground};
  width: 100%;
  z-index: 10;
  position: fixed;
  top: 55px;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  font-size: 16px;
  box-shadow: 0px 15px 26px 0px rgba(22, 33, 84, 0.04);

  ${getMQ(breakpoints.m)} {
    top: 75px;
  }
`;

// export const TopStartBtnContainer = styled.div`
//   display: none;

//   ${getMQ(breakpoints.l)} {
//     z-index: 10;
//     position: initial;
//     top: 95px;
//     left: 0;
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     margin: initial;
//     font-size: 16px;
//   }
// `;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: auto;
  padding-top: 10px;

  ${getMQ(breakpoints.l)} {
    margin: 0;
  }
`;

export const JonniLogoBlue = styled(jonniLogoBlue)`
  height: auto;
  width: 180px;
`;

export const Padding = styled.div`
  padding-bottom: 80px;

  ${getMQ(breakpoints.m)} {
    padding-bottom: 100px;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const WhatsappContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 0;

  ${getMQ(breakpoints.l)} {
    padding-left: 10px;
  }

  ${getMQ(breakpoints.xl)} {
    padding-left: 30px;
  }
`;

export const WhatsappBlueIcon = styled(whatsappBlue)`
  vertical-align: middle;

  ${getMQ(breakpoints.l)} {
    margin-right: 15px;
  }
`;

export const WhatsappWhiteIcon = styled(whatsappWhite)`
  vertical-align: middle;

  ${getMQ(breakpoints.l)} {
    margin-right: 15px;
  }
`;

export const WhatsappContactUsText = styled.div`
  display: none;

  ${getMQ(breakpoints.xl)} {
    display: inline-block;
    line-height: 24px;
    color: ${colors.primary};
    font-size: 16px;
    font-weight: 700;
  }
`;

export const MenuItem = styled.div`
  display: none;
  text-decoration: none;

  ${getMQ(breakpoints.l)} {
    color: ${colors.darkBlue};
    padding: 12px;
    margin: 0 6px;
    font-size: 16px;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const Menu = styled.div`
  ${getMQ(breakpoints.l)} {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: 60px;
  }
`;

export const DropdownMenuItem = styled.div`
  color: ${colors.secondaryBackground};
  cursor: pointer;
  margin-top: 63px;
  padding: 0px;
  font-size: 24px;
  display: flex;
  &:hover {
    opacity: 0.5;
  }
`;

export const DropdownMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${colors.primaryBackground};
  align-items: center;
  position: fixed;
  // margin-top: -600px;

  ${getMQ(breakpoints.l)} {
    display: none;
  }
`;

export const DropdownDownloadButton = styled.button`
  padding: 12px 46px;
  background: ${colors.secondaryBackground};
  border-radius: 4px;
  border: 0;
  color: ${colors.primary};
  font-weight: bold;
  font-size: 16px;
  display: flex;
  margin: 63px 0 0 0;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const DropdownOverlay = styled.button`
  height: 100%;
  width: 100%;
  background: black;
  opacity: 0.5;
  position: fixed;
  z-index: 3;
  bottom: 0;

  ${getMQ(breakpoints.l)} {
    height: 0%;
  }
`;

export const BurgerContainer = styled.div`
  cursor: pointer;

  ${getMQ(breakpoints.l)} {
    display: none;
  }
`;

export const TopBarLeftContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
