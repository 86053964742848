import { ThemeOptions } from '@mui/material';
import { colors } from 'src/gatsby-theme-material-ui-top-layout/colors';
import { fontFamily, fontSizes } from 'src/gatsby-theme-material-ui-top-layout/fonts';

export const jonniMuiThemeOverrides: ThemeOptions['components'] = {
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: fontSizes.xs_1,
        fontWeight: 400,
        color: '#9e9e9e',
      },
      body: {
        fontSize: fontSizes.xs_2,
        color: colors.light.secondary,
      },
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        background: '#fff',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: '#162154',
        fontSize: fontSizes.sm_1,
        '&:after': {
          content: '""',
          width: 0,
          height: 0,
          borderLeft: '8px solid transparent',
          borderRight: '8px solid transparent',
          borderTop: '8px solid #162154',
          display: 'flex',
          flexDirection: 'flex-start',
          justifyContent: 'center',
          position: 'absolute',
          left: '50%',
          marginLeft: -8,
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        borderSpacing: 0,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation1: {
        boxShadow: 'none',
        border: '1px solid #EEEEEE',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        '&:hover': {
          boxShadow: 'none',
        },
        boxShadow: 'none',
        paddingTop: 8,
        paddingBottom: 8,
        fontSize: fontSizes.sm_1,
      },
      containedPrimary: {
        borderRadius: '3px',
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: colors.light.primaryHover,
        },
      },
      text: {
        fontFamily,
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        overflow: 'hidden',
        '&:hover': {
          boxShadow: 'none',
        },
        '& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)': {
          border: 'none',
        },
        '& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child)': {
          border: 'none',
        },
        '& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled': {
          border: 'none',
        },
        '& .MuiButtonGroup-groupedContainedPrimary:not(:last-child)::after': {
          content: '""',
          position: 'absolute',
          width: 1,
          backgroundColor: '#3146d3',
          height: '50%',
          marginInlineStart: '100%',
          top: 10,
        },
        '& .MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled::after': {
          width: 0,
        },
      },
      contained: {
        boxShadow: 'none',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '& .MuiButton-root': {
          margin: '0 8px',
        },
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      gutters: {
        paddingInlineStart: 13,
        paddingInlineEnd: 13,
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        flexDirection: 'row',
        display: 'flex',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      caption: {
        fontSize: fontSizes.xs_1,
      },
      body1: {
        fontSize: fontSizes.sm_1,
      },
      body2: {
        fontSize: fontSizes.sm_2,
      },
      subtitle1: {
        fontSize: fontSizes.md_1,
      },
      h1: {
        fontWeight: 'bold',
      },
      h2: {
        fontWeight: 'bold',
        fontSize: fontSizes.lg_2,
      },
      h3: {
        fontSize: fontSizes.lg_1,
        fontWeight: 'bold',
      },
      h6: {
        fontWeight: 'bold',
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        fontSize: 24,
      },
    },
  },
};
