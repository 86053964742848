import { atom } from 'recoil';
import { IBusinessIndustryIndustry } from 'src/pages/signup/business-industries';
import { localStorageEffect } from 'src/recoil/steps/helpers';
import { BusinessType } from 'src/types/appsync';

export const PERSIST_KEY = 'persist:steps';

export enum Steps {
  WELCOME = 'WELCOME',
  AUTHENTICATION = 'AUTHENTICATION',
  VERIFICATION = 'VERIFICATION',
  BUSINESS_STATUS = 'BUSINESS_STATUS',
  BUSINESS_TYPE = 'BUSINESS_TYPE',
  ACCOUNTANT_EXISTENCE = 'ACCOUNTANT_EXISTENCE',
  EMPLOYEES_EXISTENCE = 'EMPLOYEES_EXISTENCE',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  BUSINESS_INDUSTRIES = 'BUSINESS_INDUSTRIES',
  MONTHLY_INCOME = 'MONTHLY_INCOME',
  GIFT_DETAILS = 'GIFT_DETAILS',
  BILLING_DETAILS = 'BILLING_DETAILS',
  AUTHORITIES_IDENTIFICATION_DETAILS = 'AUTHORITIES_IDENTIFICATION_DETAILS',
  AUTHORITIES_IDENTIFICATION_UPLOAD = 'AUTHORITIES_IDENTIFICATION_UPLOAD',
  BANK_DOCUMENT_UPLOAD = 'BANK_DOCUMENT_UPLOAD',
  DONE = 'DONE',
  LOADER = 'LOADER',
}

export const getNextStep = (step: Steps) => {
  switch (step) {
    case Steps.WELCOME:
      return Steps.WELCOME;
    case Steps.AUTHENTICATION:
      return Steps.VERIFICATION;
    case Steps.VERIFICATION:
      return Steps.BUSINESS_STATUS;
    case Steps.BUSINESS_STATUS:
      return Steps.BUSINESS_TYPE;
    case Steps.BUSINESS_TYPE:
      return Steps.ACCOUNTANT_EXISTENCE;
    case Steps.ACCOUNTANT_EXISTENCE:
      return Steps.EMPLOYEES_EXISTENCE;
    case Steps.EMPLOYEES_EXISTENCE:
      return Steps.BUSINESS_DETAILS;
    case Steps.BUSINESS_DETAILS:
      return Steps.BUSINESS_INDUSTRIES;
    case Steps.BUSINESS_INDUSTRIES:
      return Steps.MONTHLY_INCOME;
    case Steps.MONTHLY_INCOME:
      return Steps.BANK_DOCUMENT_UPLOAD;
    case Steps.BANK_DOCUMENT_UPLOAD:
  }
  return Steps.AUTHENTICATION;
};

export const getRoutePath = (step: string) => {
  return '/signup/' + step.toLowerCase().replace(/_/g, '-');
};

export const pathToStep = (path: string) => {
  const stepKey = path
    .replace('/signup/', '')
    .replace('/signup', '')
    .replace('/', '')
    .replace(/-/g, '_')
    .toUpperCase() as keyof typeof Steps;
  return Steps[stepKey];
};

export enum MapToNextStep {
  WELCOME = 'WELCOME',
  AUTHENTICATION = 'VERIFICATION',
  VERIFICATION = 'BUSINESS_STATUS',
  BUSINESS_STATUS = 'BUSINESS_TYPE',
  BUSINESS_TYPE = 'ACCOUNTANT_EXISTENCE',
  ACCOUNTANT_EXISTENCE = 'EMPLOYEES_EXISTENCE',
  EMPLOYEES_EXISTENCE = 'BUSINESS_DETAILS',
  BUSINESS_DETAILS = 'BUSINESS_INDUSTRIES',
  BUSINESS_INDUSTRIES = 'MONTHLY_INCOME',
  MONTHLY_INCOME = 'MONTHLY_INCOME',
}

export const MapScreenToStep = {
  WELCOME: 1,
  AUTHENTICATION: 1,
  VERIFICATION: 1,
  BUSINESS_STATUS: 2,
  BUSINESS_TYPE: 3,
  ACCOUNTANT_EXISTENCE: 3,
  EMPLOYEES_EXISTENCE: 3,
  BUSINESS_DETAILS: 4,
  BUSINESS_INDUSTRIES: 4,
  MONTHLY_INCOME: 4,
  GIFT_DETAILS: 5,
  BILLING_DETAILS: 5,
  AUTHORITIES_IDENTIFICATION_DETAILS: 6,
  AUTHORITIES_IDENTIFICATION_UPLOAD: 6,
  BANK_DOCUMENT_UPLOAD: 6,
  DONE: 7,
  LOADER: 0,
};

export enum VerificationFlowType {
  signIn = 'signIn',
  signUp = 'signUp',
}

export interface stepsProps {
  currentStep: Steps;
  currentUser: { id: string; registrationNumber: string; businessType: BusinessType };
  currentBusinessId: string | null;
  loading: boolean;
  verificationType: VerificationFlowType;
  businessSetup: {
    hasBusiness: boolean | null;
    businessType: BusinessType | null;
    hasAccountant: boolean | null;
    hasEmployees: boolean | null;
  };
  newBusinessDetails: {
    industry: IBusinessIndustryIndustry | null;
    secondaryIndustry: IBusinessIndustryIndustry | null;
    expectedMonthlyIncome: number | null;
  };
  authentication: { number: string };
}

const initialState: stepsProps = {
  currentStep: Steps.WELCOME,
  currentUser: { id: '', registrationNumber: '', businessType: BusinessType.PATUR },
  currentBusinessId: null,
  loading: true,
  verificationType: VerificationFlowType.signUp,
  businessSetup: {
    hasBusiness: null,
    businessType: null,
    hasAccountant: null,
    hasEmployees: null,
  },
  newBusinessDetails: { industry: null, secondaryIndustry: null, expectedMonthlyIncome: null },
  authentication: { number: '' },
};

export const stepsAtom = atom<stepsProps>({
  key: 'commonState',
  default: initialState,
  effects_UNSTABLE: [localStorageEffect(PERSIST_KEY)],
});
