export const localStorageEffect =
  (key: string) =>
  //@ts-ignore
  ({ setSelf, onSet }) => {
    const savedValue = typeof window !== 'undefined' ? localStorage.getItem(key) : null;

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any) => {
      const { loading, currentStep, ...filteredValue } = newValue;
      if (typeof window !== 'undefined') {
        localStorage.setItem(key, JSON.stringify({ ...filteredValue, loading: true, currentStep: '' }));
      }
    });
  };
