import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import LogRocket from 'logrocket';
import React from 'react';

const bugsnag = Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.GATSBY_APP_ENV,
  enabledReleaseStages: ['dev', 'test', 'prod'],
  appVersion: process.env.GATSBY_APP_VERSION,
  onError: (err) => {
    err.addMetadata('LogRocket', 'sessionURL', LogRocket.sessionURL);
  },
});

const ErrorBoundary = Bugsnag.getPlugin('react')!.createErrorBoundary(React);

// BugsnagPerformance.start({
//   apiKey: process.env.GATSBY_BUGSNAG_API_KEY || '',
//   releaseStage: process.env.GATSBY_APP_ENV,
//   enabledReleaseStages: ['dev', 'test', 'prod'],
//   appVersion: process.env.GATSBY_APP_VERSION,
// });

export { ErrorBoundary, bugsnag };
