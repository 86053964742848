import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { useEffect } from 'react';
import { bugsnag } from 'src/config/bugsnag';
import { error } from 'src/config/logger';
import { BusinessType, OnboardingStatus } from 'src/types/appsync';

const ONBOARDING_STEP_LOG_EVENT: string = 'onboarding_step';
const SCREEN_VIEW_LOG_EVENT: string = 'screen_view';
const APP_CLICK_LOG_EVENT: string = 'app_click';
const PURCHASE_LOG_EVENT: string = 'purchase';

interface SetUserPayload {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
}

const mapPathToScreenName: { [key: string]: string } = {
  '/': 'jonni.onboarding.Welcome',
  '/signup/authentication/': 'jonni.onboarding.Authentication',
  '/signup/billing-details/': 'jonni.onboarding.registration.BillingDetails',
  '/signup/accountant-existence/': 'jonni.onboarding.existingBusiness.AccountantExistence',
  '/signup/bank-document-upload/': 'jonni.onboarding.registration.BankDocumentUpload',
  '/signup/business-status/': 'jonni.onboarding.SelectBusinessStatus',
  '/signup/business-type/': 'jonni.onboarding.existingBusiness.BusinessType',
  '/signup/employees-existence/': 'jonni.onboarding.existingBusiness.EmployeesExistence',
  '/signup/business-details/': 'jonni.onboarding.registration.BusinessDetails',
  '/signup/business-industries/': 'jonni.onboarding.newBusiness.BusinessIndustries',
  '/signup/authorities-identification-details/': 'jonni.onboarding.registration.AuthoritiesIdentificationDetails',
  '/signup/authorities-identification-upload/': 'jonni.onboarding.registration.AuthoritiesIdentificationUpload',
  '/signup/monthly-income/': 'jonni.onboarding.newBusiness.SelectMonthlyIncome',
  '/signup/verification/': 'jonni.onboarding.VerificationCode',
  '/signup/done/': 'jonni.onboarding.Done',
};

export default class Analytics {
  static logEvent(eventName: string, params: { [key: string]: any } = {}) {
    return logEvent(getAnalytics(), eventName, params);
  }

  static setUser(user: SetUserPayload) {
    if (!user) return error(new Error('Cannot set user'));
    const username = `${user.firstName} ${user.lastName}`;

    try {
      bugsnag.setUser(user.id, user.email || undefined, username);
    } catch (err) {
      error(new Error('Error while setting bugsnag user'), { error: err, userId: user.id });
    }
    const analytics = getAnalytics();

    setUserId(analytics, user.id);
    setUserProperties(analytics, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    });
  }

  static logOnboardingStart(onboardingStatus: OnboardingStatus = OnboardingStatus.BUSINESS_SETUP) {
    try {
      logEvent(getAnalytics(), ONBOARDING_STEP_LOG_EVENT, {
        onboardingStatus,
      });
    } catch (err) {
      error(new Error('Error while logging onboarding start event'), { error: err });
    }
  }

  static logOnboardingFinish() {
    try {
      logEvent(getAnalytics(), ONBOARDING_STEP_LOG_EVENT, {
        onboardingStatus: OnboardingStatus.DONE,
      });
    } catch (err) {
      error(new Error('Error while logging onboarding finish event'), { error: err });
    }
  }

  static logNewOnboardingStep(newOnboardingStatus: OnboardingStatus) {
    try {
      logEvent(getAnalytics(), ONBOARDING_STEP_LOG_EVENT, {
        onboardingStatus: newOnboardingStatus,
      });
    } catch (err) {
      error(new Error('Error while logging new onboarding step'), { error: err, newOnboardingStatus });
    }
  }

  static logScreenView(path: string) {
    try {
      logEvent(getAnalytics(), SCREEN_VIEW_LOG_EVENT, {
        firebase_screen: mapPathToScreenName[path],
      });
    } catch (err) {
      error(new Error('Error while logging screen view'), { error: err });
    }
  }

  static logAppButtonClick(appName: string) {
    try {
      logEvent(getAnalytics(), APP_CLICK_LOG_EVENT, {
        app_name: appName,
      });
    } catch (err) {
      error(new Error('Error while logging app click'), { error: err });
    }
  }

  static logPurchase(price: number, businessType: BusinessType) {
    try {
      logEvent(getAnalytics(), PURCHASE_LOG_EVENT, {
        businessType: businessType,
        price: price,
      });
    } catch (err) {
      error(new Error('Error while logging purchase'), { error: err });
    }
  }
}

export function useLogScreenView(pathname: string) {
  useEffect(() => {
    Analytics.logScreenView(pathname);
  }, []);
}
