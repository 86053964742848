import { bugsnag } from 'src/config/bugsnag';

/**
 *
 * @param msg Describes the error in a few words
 * @param rest Object specifying extra parameters regarding the error
 */
export function info(msg: Error | any, rest?: { [key: string]: any }) {
  if (process.env.GATSBY_APP_ENV === ('dev' || 'test' || 'prod')) {
    const extraData: any = {};
    for (const key in rest) {
      const val = rest[key];
      extraData[key] = JSON.stringify(val) === '{}' ? val.toString() : JSON.stringify(val);
    }
    bugsnag.notify(msg instanceof Error ? msg : new Error(msg), (report) => {
      report.severity = 'info';
      report.addMetadata('extraData', { ...extraData });
    });
  } else {
    console.warn(msg, { ...rest });
  }
}

/**
 *
 * @param msg Describes the error in a few words
 * @param rest Object specifying extra parameters regarding the error
 */
export function error(msg: Error | any, rest?: { [key: string]: any }) {
  if (process.env.GATSBY_APP_ENV === ('dev' || 'test' || 'prod')) {
    const extraData: any = {};
    for (const key in rest) {
      const val = rest[key];
      extraData[key] = JSON.stringify(val) === '{}' ? val.toString() : JSON.stringify(val);
    }
    bugsnag.notify(msg instanceof Error ? msg : new Error(msg), (report) => {
      report.addMetadata('extraData', { ...extraData });
    });
  } else {
    console.warn(msg, { ...rest });
  }
}
