import { GraphQLResult } from '@aws-amplify/api-graphql';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { navigate } from 'gatsby';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { bugsnag } from 'src/config/bugsnag';
import { error } from 'src/config/logger';
import { API } from 'src/helpers';
import useMaintenance from 'src/hooks/useMaintenance';
import { Steps, getRoutePath, stepsAtom, stepsProps } from 'src/recoil/steps/atoms';
import { BusinessType, Mutation, OnboardingStatus, Query } from 'src/types/appsync';

const getInitialUserQuery = /* GraphQL */ `
  query getUser {
    getUser {
      id
      onboardingStatus
      firstName
      lastName
      mobile
      idNumber
      businesses {
        id
        businessType
      }
    }
  }
`;

const signUpMutation = /* GraphQL */ `
  mutation SignUp($input: SignUpInput) {
    signUp(input: $input) {
      id
      onboardingStatus
    }
  }
`;

export function onboardingStatusToScreenName(onboardingStatus: OnboardingStatus): Steps {
  if (onboardingStatus === OnboardingStatus.BUSINESS_SETUP) {
    return Steps.BUSINESS_STATUS;
  } else if (onboardingStatus === OnboardingStatus.BUSINESS_DETAILS) {
    return Steps.BUSINESS_DETAILS;
  } else if (onboardingStatus === OnboardingStatus.NEW_BUSINESS_DETAILS) {
    return Steps.BUSINESS_INDUSTRIES;
  } else if (onboardingStatus === OnboardingStatus.GIFT_DETAILS) {
    return Steps.GIFT_DETAILS;
  } else if (onboardingStatus === OnboardingStatus.BILLING_DETAILS) {
    return Steps.BILLING_DETAILS;
  } else if (onboardingStatus === OnboardingStatus.AUTHORITIES_IDENTIFICATION) {
    return Steps.AUTHORITIES_IDENTIFICATION_DETAILS;
  } else if (onboardingStatus === OnboardingStatus.IDENTIFICATION_UPLOAD) {
    return Steps.AUTHORITIES_IDENTIFICATION_UPLOAD;
  } else if (onboardingStatus === OnboardingStatus.DONE) {
    return Steps.DONE;
  }

  return Steps.AUTHORITIES_IDENTIFICATION_UPLOAD;
}

export const fetchCurrentUser = async (stepsState: stepsProps, setStepsState: any, registered?: boolean) => {
  try {
    try {
      await getCurrentUser();
    } catch (err: any) {
      // If this throws it means the user isn't signed in
      setStepsState({ ...stepsState, currentStep: Steps.WELCOME, loading: false });
      await navigate(getRoutePath(Steps.WELCOME));
      return;
    }

    const res = (await API.graphql({ query: getInitialUserQuery })) as GraphQLResult<{ getUser: Query['getUser'] }>;
    const userData = res.data?.getUser;
    if (userData) {
      bugsnag.setUser(userData.id, userData.mobile, `${userData.firstName} ${userData.lastName}`);

      const currentStep = onboardingStatusToScreenName(userData.onboardingStatus);
      setStepsState({
        ...stepsState,
        loading: false,
        currentStep: currentStep,
        currentUser: {
          id: userData.id,
          registrationNumber: userData.idNumber,
          businessType: userData.businesses?.[0] ? userData.businesses[0].businessType : BusinessType.PATUR,
        },
        currentBusinessId: userData.businesses?.[0] ? userData.businesses[0].id : '',
        authentication: {
          ...stepsState.authentication,
          number: `${'0' + userData.mobile.slice(4)}`,
        },
      });

      const currentUrl = getRoutePath(currentStep);
      await navigate(currentUrl);
    }

    if (!userData && !registered) {
      try {
        await registerCurrentUser();
      } catch (err: any) {
        error(new Error('Error while trying to call signUp mutation for signed in user'), {
          error: err,
          mutation: 'signUp',
          phoneNumber: stepsState.authentication.number,
        });
        setStepsState({ ...stepsState, currentStep: Steps.WELCOME, loading: false });
        await navigate(getRoutePath(Steps.WELCOME));
        return;
      }

      fetchCurrentUser(stepsState, setStepsState, true);
      return;
    }
  } catch (err) {
    toast.error('ההתחברות נכשלה, אפשר לנסות שוב', {
      position: 'bottom-right',
    });
    error(new Error('Error while featching user'), {
      error: err,
      query: 'getUser',
      phoneNumber: stepsState.authentication.number,
    });
    setStepsState({ ...stepsState, currentStep: Steps.AUTHENTICATION, loading: false });
    await navigate(getRoutePath(Steps.AUTHENTICATION));
  }
};

export const registerCurrentUser = async () => {
  try {
    const { identityId } = await fetchAuthSession();
    const res = (await API.graphql({
      query: signUpMutation,
      variables: {
        input: {
          identityId,
        },
      },
    })) as GraphQLResult<{ signUp: Mutation['signUp'] }>;

    const user = res?.data?.signUp;
    if (user?.id) {
      return user.id;
    } else {
      throw new Error('signUpMutation - Received success response but no user was provided in response body');
    }
  } catch (err) {
    throw err;
  }
};

interface NavigateToActiveScreenProps {}

export const NavigateToActiveScreen: React.FC<NavigateToActiveScreenProps> = () => {
  const [stepsState, setStepsState] = useRecoilState(stepsAtom);
  const { isOnMaintenance } = useMaintenance();

  useEffect(() => {
    if (isOnMaintenance) {
      navigate('/maintenance');
    } else {
      fetchCurrentUser(stepsState, setStepsState, false);
    }
  }, []);

  return null;
};
