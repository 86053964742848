import React from 'react';
import {
  StepsMobileContainer,
  StyledStepTitle,
  StyledStepsText,
  StyledStep,
} from 'src/styles/signup/signupMobileStyles';
import { steps } from 'src/data';
import { MapScreenToStep, Steps } from 'src/recoil/steps/atoms';
import CircularProgressSteps from 'src/components/Steps/CircularProgressSteps';
import { Theme, useMediaQuery } from '@mui/material';

interface Props {
  activeStep: Steps;
}

const StepsMobile: React.FC<Props> = ({ activeStep }) => {
  const step = MapScreenToStep[activeStep];
  const isMobileWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return isMobileWidth ? (
    <StepsMobileContainer>
      {steps.map((item, i) => {
        return (
          <StyledStep nextStep={i === step - 1} key={item.title}>
            <StyledStepsText center={i}>
              <StyledStepTitle center={i}>{item.title}</StyledStepTitle>
            </StyledStepsText>
            <div>{i !== 0 ? <CircularProgressSteps step={i} /> : null}</div>
          </StyledStep>
        );
      })}
    </StepsMobileContainer>
  ) : null;
};

export default StepsMobile;
