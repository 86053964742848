import { createTheme } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { colors } from 'src/gatsby-theme-material-ui-top-layout/colors';
import { fontFamily } from 'src/gatsby-theme-material-ui-top-layout/fonts';
import { jonniMuiThemeOverrides } from 'src/gatsby-theme-material-ui-top-layout/styles';

const themeOptionsLight: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: colors.light.primary,
    },
    secondary: {
      main: colors.light.secondary,
    },
    text: {
      primary: colors.light.text,
      secondary: colors.light.darkBlue,
    },
    error: {
      main: colors.light.error,
    },
    success: {
      main: colors.light.success,
    },
    warning: {
      main: '#000',
      light: '#000',
      dark: '#000',
    },
    action: {
      disabledBackground: '#F6F6F6',
      disabled: '#c3c3c3',
    },
  },
  typography: {
    fontFamily,
  },

  components: jonniMuiThemeOverrides,
};

const theme = createTheme({
  ...themeOptionsLight,
  direction: 'rtl',
});

export default theme;
