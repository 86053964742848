import { Amplify } from 'aws-amplify';
import { disable as disableAnalytics } from 'aws-amplify/analytics';

disableAnalytics();
Amplify.configure(
  {
    API: {
      GraphQL: {
        endpoint: `https://api.${process.env.GATSBY_JONNI_DOMAIN}/graphql`,
        region: process.env.GATSBY_CLOUD_AWS_REGION as string,
        defaultAuthMode: 'userPool',
      },
    },
    Auth: {
      Cognito: {
        identityPoolId: process.env.GATSBY_CLOUD_AWS_COGNITO_IDENTITY_POOL_ID as string,
        userPoolId: process.env.GATSBY_CLOUD_AWS_USER_POOLS_ID as string,
        userPoolClientId: process.env.GATSBY_CLOUD_AWS_USER_POOLS_WEB_CLIENT_ID as string,
      },
    },
  },
  {
    API: {
      GraphQL: {
        headers: async () => ({
          platform: 'website',
          'app-version': process.env.GATSBY_APP_VERSION,
        }),
      },
    },
  },
);
