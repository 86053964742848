export const steps = [
  {
    number: 0,
    title: 'אימות',
    description: 'נועד לצורך אימות והתחברות למערכת',
  },
  {
    number: 1,
    title: 'עסק חדש או קיים?',
    description: 'עסק קיים או שאתם רוצים לפתוח עסק חדש?',
  },
  {
    number: 2,
    title: 'סוג העוסק',
    description: 'נוכל ביחד להתאים עבורך ולדעת איך לעבוד ביחד',
  },
  {
    number: 3,
    title: 'פרטי העסק',
    description: 'הפרטים נועדו לצורך קליטת העסק ולקבלת ייפוי כוח',
  },
  {
    number: 4,
    title: ' תשלום לשירות',
    description: 'בחירת ואישור אמצעי תשלום מאובטח',
  },
  {
    number: 5,
    title: 'פרטי הזדהות',
    description: 'התממשקות עם ביטוח לאומי ומס הכנסה',
  },
  {
    number: 6,
    title: 'סיום',
    description: 'אישור והורדת האפליקציה למכשיר',
  },
];
