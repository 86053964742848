import { useState, useEffect, useCallback } from 'react';
import { error } from 'src/config/logger';

const useMaintenance = () => {
  const [isOnMaintenance, setIsOnMaintenance] = useState(false);

  const fetchMaintenanceStatus = useCallback(async () => {
    try {
      const res = await fetch(`https://api.${process.env.GATSBY_JONNI_DOMAIN}/status`);
      const serviceStatus = res.headers.get('service-status');
      if (serviceStatus === '110') setIsOnMaintenance(true);
    } catch (err) {
      error('Error fetching maintenance status', { error: err });
    }
  }, []);

  useEffect(() => {
    fetchMaintenanceStatus();
  }, []);

  return { isOnMaintenance };
};

export default useMaintenance;
