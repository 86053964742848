export const socialLinks = {
  facebook: process.env.GATSBY_FACEBOOK_PAGE_URL,
  linkedin: process.env.GATSBY_LINKEDIN_PAGE_URL,
  whatsapp: process.env.GATSBY_WHATSAPP_PAGE_URL,
  instagram: process.env.GATSBY_INSTAGRAM_PAGE_URL,
};

export const downloadLinks = {
  appStore: process.env.GATSBY_APP_STORE_APP_URL,
  googlePlay: process.env.GATSBY_GOOGLE_PLAY_APP_URL,
};

export const taxAuthorityLink = process.env.GATSBY_TAX_AUTHORITY_CERTIFICATE_URL;
export const privacyAuthorityLink = process.env.GATSBY_PRIVACY_AUTHORITY_URL;
export const AppendixLink = 'https://www.gov.il/he/service/renew_id_appendix';
