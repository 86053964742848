import React from 'react';
import { Link } from 'gatsby';
import { socialLinks } from 'src/config';
import { HeaderLogo, JonniLogo, BlueWhatsappIcon, HeaderContainer } from 'src/styles/signup/signupSteps';
import Analytics from 'src/helpers/analytics';

interface SignupLayoutHeaderProps {}

export const SignupLayoutHeader: React.FC<SignupLayoutHeaderProps> = () => {
  const onAppClick = (name: string) => {
    Analytics.logAppButtonClick(name);
  };

  return (
    <HeaderContainer>
      <HeaderLogo>
        <Link to={'https://www.' + process.env.GATSBY_JONNI_DOMAIN}>
          <JonniLogo />
        </Link>
      </HeaderLogo>
      <a onClick={() => onAppClick('whatsapp')} href={socialLinks.whatsapp} target="_blank" rel="noreferrer">
        <BlueWhatsappIcon />
      </a>
    </HeaderContainer>
  );
};

export default SignupLayoutHeader;
