export const fontSizes = {
  xs_1: '0.667rem', // 12px
  xs_2: '0.722rem', // 13px
  sm_1: '0.778rem', // 14px
  sm_2: '0.889rem', // 16px
  md_1: '1rem', // 18px
  md_2: '1.111rem', // 20px
  lg_1: '1.333rem', // 24px
  lg_2: '1.778rem', //32px
};

export const fontFamily = [
  'Heebo',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');
