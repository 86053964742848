/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { ThemeProvider } from '@mui/material/styles';
import { Location } from '@reach/router';
import { initializeApp } from 'firebase/app';
import React from 'react';
import { RecoilRoot } from 'recoil';
import 'src/config/bugsnag';
import 'src/config/logrocket';
import theme from 'src/gatsby-theme-material-ui-top-layout/theme.ts';
import MainLayout from 'src/layouts/MainLayout';

initializeApp(JSON.parse(process.env.GATSBY_FIREBASE_CONFIG_KEY));

export const wrapRootElement = ({ element }) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <RecoilRoot>
            <ThemeProvider theme={theme}>
              <MainLayout element={element} pathName={location.pathname} />
            </ThemeProvider>
          </RecoilRoot>
        );
      }}
    </Location>
  );
};
