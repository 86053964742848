/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import styled from '@emotion/styled';
import colors from 'src/styles/colors';
import 'src/components/layout.css';

type LayoutContainerProps = {
  background?: string;
};

export const LayoutContainer = styled.div<LayoutContainerProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin: 0px;
  align-items: center;
  background: ${(props) => (props.background ? props.background : colors.secondaryBackground)};
`;

interface Props {
  children: React.ReactElement[] | React.ReactElement;
  background?: string;
}

const Layout: React.FC<Props> = ({ background, children }) => {
  return <LayoutContainer background={background}>{children}</LayoutContainer>;
};

export default Layout;
