export const colors = {
  light: {
    background: '#160c28',
    backgroundGray: '#ebebeb',
    boxInfo: '#dcedfe',
    error: '#F25551',
    info: '#323232',
    primaryDisable: '#ffffff',
    primaryHover: '#3246D0',
    secondary: '#0E1B53',
    success: '#2FE186',
    text: '#888888',
    primary: '#4357F2',
    primaryBackground: '#3548CF',
    secondaryBackground: '#FAFBFF',
    darkBlue: '#162154',
    lightGray: '#FAFAFA',
    borderGray: '#F6F6F6',
    gray: '#EEEEEE',
    darkGray: '#888888',
    red: '#F25652',
    green: '#33E085',
  },
};
