import React, { useEffect, useState } from 'react';
import SignUpLayout from 'src/layouts/SignupLayout';
import { NavigateToActiveScreen } from 'src/layouts/components/NavigateToActiveScreen';

interface MainLayoutProps {
  element: any;
  pathName: any;
}

const MainLayout: React.FC<MainLayoutProps> = ({ element, pathName }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setLoading(false);
    }, 200);

    return () => clearTimeout(loadingTimeout);
  }, []);

  if (loading) {
    return <div style={{ display: 'none' }}>{element}</div>;
  }

  return pathName.includes('signup') ? (
    <>
      <NavigateToActiveScreen />
      <SignUpLayout path={pathName}>{element}</SignUpLayout>
    </>
  ) : (
    <>{element}</>
  );
};
export default MainLayout;
