/* eslint-disable import/no-anonymous-default-export */
export default {
  primaryBackground: '#3548CF',
  secondaryBackground: '#FFF',
  lightBackground: '#F8F9FF',
  grayBackground: '#fafbff',
  primary: '#4358F2',
  darkBlue: '#162154',
  borderGray: '#C3C3C3',
  lightBorder: '#DCDEE5',
  lightGray: '#fafbff',
  footer: '#EBF3FA',
  blue: '#0cb5f7',
  lightBlue: '#65d1fa',
  white: '#FFF',
  gray: '#c9c9c9',
  darkGray: '#acabab',
  black: '#000',
  error: '#F25551',
  lightGreen: '#9AFBC8',
  lightBlueText: '#16215480',
};
